import { template as template_f0ffd5053f184d028a0a46564a94db5e } from "@ember/template-compiler";
const FKLabel = template_f0ffd5053f184d028a0a46564a94db5e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
