import { template as template_b83a5aec3d864561aa875bd9defb5703 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_b83a5aec3d864561aa875bd9defb5703(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
