import { template as template_ccb2e32a34da470ca9ae5a2bd0e856f6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ccb2e32a34da470ca9ae5a2bd0e856f6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
