import { template as template_c3c7baf5b7214aa196a9935eba131837 } from "@ember/template-compiler";
const WelcomeHeader = template_c3c7baf5b7214aa196a9935eba131837(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
